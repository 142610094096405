* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0 solid transparent;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  list-style: none;
}

.simulator-container {
  height: 100%;
  width: 100%;
  padding: 20px;

  a,
  u {
    text-decoration: none;
  }
}

.simulator-error {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background-color: #ee7c88;
  color: #721c24;
  font-family: Arial, sans-serif;
  flex-direction: column;
  overflow: auto;
}

.error-container {
  display: block;
  flex-direction: center;
  align-items: flex-start;
}

.error-message {
  display: flex;
  flex: none;
  white-space: 'nowrap';
  align-items: center;
}

.error-message svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  fill: #fff;
}

.card {
  border-radius: 17px;
  width: 300px;
  border-radius: 17px;
  overflow: hidden;
  background-color: #ffffff;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 12px;
  height: fit-content;
  flex: none;

  &.giga {
    width: 400px;
  }

  &.mega {
    width: 300px;
  }

  &.kilo {
    width: 260px;
  }

  &.hecto {
    width: 241px;
  }

  &.deca {
    width: 220px;
  }

  &.micro {
    width: 160px;
  }

  &.nano {
    width: 120px;
  }

  .header {
    display: inherit;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;

    .box {
      padding: 20px;
    }
  }

  .hero {
    display: inherit;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;

    &:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
  }

  .box {
    &.horizontal {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      width: 100%;

      >div {
        width: 0;
        overflow: hidden;
        -webkit-box-flex: 1;
        flex: 1 1 auto;
      }

      >div.flex-0 {
        width: auto;
      }

      >.seperator {
        -webkit-box-flex: 0;
        flex: none;
        overflow: visible;

        &::before {
          width: 1px;
          height: 100%;
          border-top-width: 0;
          border-left-width: 1px;
          -webkit-transform: scaleX(0.5);
          transform: scaleX(0.5);
        }
      }
    }

    &.baseline {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      width: 100%;

      -webkit-box-align: baseline;
      flex-align: baseline;
      align-items: baseline;
    }

    .vertical {
      >div {
        flex: none;
        -webkit-box-flex: 0;

        &:first-child,
        &.filler,
        &.spacer {
          margin-top: 0;
        }
      }
    }

    >.filler {
      -webkit-box-flex: 1 !important;
      flex: 1 1 auto !important;
    }
  }

  .footer {
    display: inherit;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;

    >.box {
      padding: 10px;
    }
  }

  .body {
    display: inherit;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .box,
  .text,
  .image,
  .button,
  .seperator {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    display: -webkit-box;
    display: flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: auto;
    min-width: 0;
    max-width: 100%;
  }

  .image {
    span {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    &.fit {
      span {
        background-size: contain;
      }
    }

    &.cover {
      span {
        background-size: cover;
      }
    }

    a {
      display: block;
      position: relative;
      width: 100%;
    }

    >div {
      width: 100%;
      max-width: 100%;
    }

    &.md {
      >div {
        width: 100px;
      }
    }
  }

  .button {
    border-radius: 8px;

    &.vertical {
      >div {
        -webkit-box-flex: 0;
        flex: none;
      }
    }

    a {
      display: inherit;
      -webkit-box-align: center;
      flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      flex-pack: center;
      justify-content: center;
      width: 100%;
      height: 52px;
      padding: 0 16px;
      border-radius: 8px;
      font-size: 16px;
      color: #42659a;
    }
  }

  .seperator {
    box-sizing: content-box;
    width: 100%;
    border: 0 solid #d4d6da;

    position: relative;

    &::before {
      box-sizing: content-box;
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 1px;
      border: inherit;
      border-top-width: 1px;
      -webkit-transform-origin: left top;
      transform-origin: left top;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }

  .spacer {
    height: 8px;
    width: 1px;
  }

  .icon {
    span {
      display: inline-block;
      overflow: hidden;
      background: no-repeat center;
      background-size: contain;
      width: 1em;
      height: 1em;
      vertical-align: baseline;
    }
  }

  .text {
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.box {
  overflow: hidden;
  position: relative;
}

.text {
  position: relative;
}

.image {
  -webkit-box-align: center;
  text-align: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.button {
  position: relative;
  cursor: pointer;
}

.icon {
  position: relative;
}

@mixin spacingMarginLeft($margin) {
  & {
    >div {
      &:not(:first-child) {
        margin-left: #{$margin};
      }
    }
  }
}

// spacing
.horizontal,
.baseline {
  &.spacing-xs {
    >div {
      &:not(:first-child) {
        margin-left: 2px;
      }
    }
  }

  &.spacing-sm {
    >div {
      &:not(:first-child) {
        margin-left: 4px;
      }
    }
  }

  &.spacing-md {
    >div {
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }

  &.spacing-lg {
    >div {
      &:not(:first-child) {
        margin-left: 12px;
      }
    }
  }

  &.spacing-xl {
    >div {
      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }

  &.spacing-xxl {
    >div {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  @for $i from 0 through 100 {
    &.spacing-#{$i} {
      @include spacingMarginLeft(#{$i}px);
    }
  }
}

@mixin spacingMarginTop($margin) {
  & {
    >div {
      &:not(:first-child) {
        margin-top: #{$margin};
      }
    }
  }
}

.vertical {
  &.spacing-xs {
    >div {
      &:not(:first-child) {
        margin-top: 2px;
      }
    }
  }

  &.spacing-sm {
    >div {
      &:not(:first-child) {
        margin-top: 4px;
      }
    }
  }

  &.spacing-md {
    >div {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  &.spacing-lg {
    >div {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }

  &.spacing-xl {
    >div {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  &.spacing-xxl {
    >div {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  @for $i from 0 through 100 {
    &.spacing-#{$i} {
      @include spacingMarginTop(#{$i}px);
    }
  }
}

@mixin marginTop($margin) {
  & {
    &:not(:first-child) {
      margin-top: #{$margin} !important;
    }
  }
}

// margin
.vertical {
  >.margin-xs {
    &:not(:first-child) {
      margin-top: 2px !important;
    }
  }

  >.margin-sm {
    &:not(:first-child) {
      margin-top: 4px !important;
    }
  }

  >.margin-md {
    &:not(:first-child) {
      margin-top: 8px !important;
    }
  }

  >.margin-lg {
    &:not(:first-child) {
      margin-top: 12px !important;
    }
  }

  >.margin-xl {
    &:not(:first-child) {
      margin-top: 16px !important;
    }
  }

  >.margin-xxl {
    &:not(:first-child) {
      margin-top: 20px !important;
    }
  }

  @for $i from 0 through 100 {
    >.margin-#{$i} {
      @include marginTop(#{$i}px);
    }
  }
}

@mixin marginLeft($margin) {
  & {
    &:not(:first-child) {
      margin-left: #{$margin} !important;
    }
  }
}

.horizontal,
.baseline {
  >.margin-xs {
    &:not(:first-child) {
      margin-left: 2px !important;
    }
  }

  >.margin-sm {
    &:not(:first-child) {
      margin-left: 4px !important;
    }
  }

  >.margin-md {
    &:not(:first-child) {
      margin-left: 8px !important;
    }
  }

  >.margin-lg {
    &:not(:first-child) {
      margin-left: 12px !important;
    }
  }

  >.margin-xl {
    &:not(:first-child) {
      margin-left: 16px !important;
    }
  }

  >.margin-xxl {
    &:not(:first-child) {
      margin-left: 20px !important;
    }
  }

  @for $i from 0 through 100 {
    >.margin-#{$i} {
      @include marginLeft(#{$i}px);
    }
  }
}