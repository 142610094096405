.container {
	height: 100vh;
	width: 100%;
	display: flex;
	overflow: hidden;

	.preview-container {
		flex: 1;
		height: 100%;
		background-color: transparent;
		overflow-x: auto;
	}
}
